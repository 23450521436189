import React, { useState } from 'react';
import Config from '../config.json';
import LoginCheck from '../pages/logincheck';

const AIVoiceProspect = () => {
    const [customerData, setCustomerData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!customerData.trim()) {
            setErrorMessage('Please enter some customer data before submitting.');
            return;
        }

        setErrorMessage('');
        setStatusMessage('Submitting...');

        try {
            // const apiURL = window.location.hostname === "galeforce.net" ? "https://galeforce.ai/prospect-data" : "https://renewed-wallaby-concise.ngrok-free.app/prospect-data";
            const apiURL = 'https://68hyuz7nz8.execute-api.us-east-2.amazonaws.com/aivoiceprospectrequest';
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Config.aivoiceprospectrequest
                },
                body: JSON.stringify({
                    data: customerData,
                    request_id: requestId()
                })
            });

            if (response.ok) {
                // setCustomerData('');
                setStatusMessage('Successfully submitted!');
                const responseData = await response.json();
                console.log("Response from server:", responseData);
            } else {
                const errorData = await response.json();
                setStatusMessage(`Error: ${errorData.message || 'Submission failed.'}`);
            }
        } catch (error) {
            setStatusMessage(`Error: ${error.message || 'Something went wrong.'}`);
        }
    };

    const requestId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}`;
    }

    return (
        <div>
            <h1 className="page-header">
				AI Voice Prospect<small></small>
			</h1>

            <form 
                onSubmit={handleSubmit} 
                style={{
                    padding: '2rem', 
                    borderRadius: '8px', 
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    width: '500px'
                }}
            >
                <textarea
                    placeholder="Type customer data here..."
                    value={customerData}
                    onChange={(e) => setCustomerData(e.target.value)}
                    style={{
                        width: '100%', 
                        height: '100px', 
                        resize: 'none', 
                        padding: '0.5rem', 
                        marginBottom: '1rem',
                        border: '1px solid #ccc', 
                        borderRadius: '4px'
                    }}
                />

                {errorMessage && (
                    <div style={{ 
                        color: 'red', 
                        marginBottom: '1rem', 
                        fontSize: '0.9rem' 
                    }}>
                        {errorMessage}
                    </div>
                )}

                <button 
                    type="submit"
                    style={{
                        width: '100%', 
                        padding: '0.75rem', 
                        fontSize: '1rem', 
                        borderRadius: '4px', 
                        border: 'none', 
                        background: '#1890ff', 
                        color: '#fff', 
                        cursor: 'pointer'
                    }}
                >
                    Submit
                </button>

                {statusMessage && (
                    <div style={{ 
                        marginTop: '1rem', 
                        fontSize: '0.95rem', 
                        color: '#333', 
                        textAlign: 'center' 
                    }}>
                        {statusMessage}
                    </div>
                )}
            </form>
        </div>
    );
}

export default AIVoiceProspect;
