import React, { useState } from 'react';
import Config from '../config.json';
import LoginCheck from '../pages/logincheck';

const DealerFileUpload = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [excelFile, setExcelFile] = useState(null);  // New state to track the selected Excel file
    const [fileInformation, setFileInformation] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    // New function to handle file selection
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            setExcelFile(null);
            return;
        }

        // Check MIME type for .xls or .xlsx
        if (
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            setExcelFile(file);
            setErrorMessage('');
        } else {
            // Display an error if file is not an Excel type
            setErrorMessage('Please upload a valid Excel file (.xls or .xlsx).');
            setExcelFile(null);
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();

        // Check if a file is selected
        if (!excelFile) {
            setErrorMessage('Please upload an Excel file before submitting.');
            return;
        }

        setErrorMessage('');
        setStatusMessage('Submitting...');

        try {
            // Instead of reading the file in base64, we'll do a simple file upload using FormData
            const formData = new FormData();
            // You can call this key anything; I'm naming it "excelFile" to be descriptive
            formData.append('excelFile', excelFile, excelFile.name);
            // If you still need to send additional data, you can append it like so:
            formData.append('fileInformation', fileInformation);
            formData.append('request_id', requestId());

            console.log('Hostname: ', window.location.hostname);

            const apiURL = window.location.hostname === '192.168.1.45' 
                ? 'http://localhost:8383/loanverification/dealerfileupload'
                : 'https://da69s8y7lg.execute-api.us-east-2.amazonaws.com/loanverificationrequest';
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    // With FormData, avoid setting 'Content-Type' manually
                    // 'Authorization' can still be included
                    'Authorization': Config.loanverificationdealerfileupload
                },
                body: formData
            });

            if (response.ok) {
                setStatusMessage('Successfully submitted!');
                const responseData = await response.json();
                console.log('Response from server:', responseData);
                
                // Redirect to /loanverification/dealerfiles after success
                window.location.href = '/loanverification/dealerfiles';

            } else {
                const errorData = await response.json();
                setStatusMessage(`Error: ${errorData.message || 'Submission failed.'}`);
            }

        } catch (error) {
            setStatusMessage(`Error: ${error.message || 'Something went wrong.'}`);
        }
    };

    const requestId = () => {
        return `${localStorage.getItem('user_id')}-${localStorage.getItem('optimus')}`;
    };

    return (
        <div>
            <h1 className="page-header">
                Loan Verification<small></small>
            </h1>

            <form
                onSubmit={handleFileUpload}
                style={{
                    padding: "2rem",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    width: "500px"
                }}
            >
                <input
                    type="file"
                    accept=".xls,.xlsx"
                    onChange={handleFileChange}
                    style={{
                        marginBottom: "1rem",
                        display: "block"
                    }}
                />

                <textarea
                    placeholder="Enter any additional file information here if needed."
                    value={fileInformation}
                    onChange={(e) => setFileInformation(e.target.value)}
                    style={{
                        width: "80%",
                        height: "85px",
                        resize: "none",
                        padding: "0.5rem",
                        marginBottom: "0.7rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px"
                    }}
                />

                {errorMessage && (
                    <div
                        style={{
                            color: "red",
                            marginBottom: "1rem",
                            fontSize: "0.9rem"
                        }}
                    >
                        {errorMessage}
                    </div>
                )}

                <button
                    type="submit"
                    style={{
                        width: "46%",
                        padding: "0.75rem",
                        fontSize: "1rem",
                        borderRadius: "4px",
                        border: "none",
                        background: "#1890ff",
                        color: "#fff",
                        cursor: "pointer"
                    }}
                >
                    Submit
                </button>

                {statusMessage && (
                    <div
                        style={{
                            marginTop: "1rem",
                            fontSize: "0.95rem",
                            color: "#333",
                            textAlign: "center"
                        }}
                    >
                        {statusMessage}
                    </div>
                )}
            </form>
        </div>
    );
};

export default DealerFileUpload;
