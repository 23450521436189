import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';  // <-- import for links
import Config from '../config.json';

/**
 * LoanVerificationDealerFiles
 * This page fetches a list of dealer files from the loanverification/dealerlist endpoint
 * and displays them in a simple table.
 */
const DealerFiles = () => {
    const [dealerFiles, setDealerFiles] = useState([]);      // Stores the list of files
    const [errorMessage, setErrorMessage] = useState('');    // Stores any error message
    const [statusMessage, setStatusMessage] = useState('');  // Stores a loading or status message

    // Dynamically decide which base URLs to call depending on the hostname
    const getBaseURL = window.location.hostname === '192.168.1.45'
        ? 'http://localhost:8383/loanverification'
        : 'https://da69s8y7lg.execute-api.us-east-2.amazonaws.com/loanverification';

    useEffect(() => {
        // Fetch the dealer files on component mount
        const fetchDealerFiles = async () => {
            setStatusMessage('Loading dealer files...');
            try {
                const response = await fetch(`${getBaseURL}/dealerfilelist`, {
                    method: 'GET',
                    headers: {
                        // If your backend requires an Authorization header, include it here.
                        'Authorization': Config.loanverificationrequest
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch dealer files');
                }
                const data = await response.json();
                setDealerFiles(data);
                setStatusMessage('');
            } catch (error) {
                setErrorMessage(error.message);
                setStatusMessage('');
            }
        };

        fetchDealerFiles();
    }, [getBaseURL]);

    // Handler for deleting a dealer file
    const handleDeleteFile = async (fileId) => {
        setStatusMessage(`Deleting file with ID: ${fileId}...`);
        try {
            const response = await fetch(`${getBaseURL}/dealerfiledelete/${fileId}`, {
                method: 'POST',
                headers: {
                    'Authorization': Config.loanverificationrequest
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to delete file with ID: ${fileId}`);
            }
            // Remove the deleted file from the state
            setDealerFiles((prevFiles) => prevFiles.filter((file) => file._id !== fileId));
            setStatusMessage(`File with ID: ${fileId} deleted successfully.`);
        } catch (error) {
            setErrorMessage(error.message);
            setStatusMessage('');
        }
    };

    return (
        <div>
            <h1 className="page-header">
                Dealer Files
            </h1>

            {statusMessage && (
                <div style={{ marginBottom: '1rem', fontSize: '0.95rem' }}>
                    {statusMessage}
                </div>
            )}

            {errorMessage && (
                <div style={{ marginBottom: '1rem', color: 'red' }}>
                    {errorMessage}
                </div>
            )}

            {/* A simple table layout for the fetched dealer files. Adjust columns as needed. */}
            <table style={{ borderCollapse: 'collapse', width: '80%' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Dealer Name</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>File Name</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Upload Date</th>
                        <th style={{ border: '1px solid #ccc', padding: '8px' }}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {dealerFiles.map((file, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                {/* Link to detail page, using _id */}
                                <Link to={`/loanverification/dealerfiles/${file._id}`}>
                                    {file.dealerName}
                                </Link>
                            </td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                <Link to={`/loanverification/dealerfiles/${file._id}`}>
                                    {file.fileName}
                                </Link>
                            </td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                {file.uploadDate}
                            </td>
                            <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                {/* Delete button */}
                                <button onClick={() => {
                                    if (window.confirm("Are you sure you want to delete this file?")) {
                                        handleDeleteFile(file._id);
                                    }
                                }}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div style={{ marginTop: '1rem' }}>
                <Link to='/loanverification/dealerfileupload'>
                    <button>Upload New Dealer File</button>
                </Link>
            </div>

        </div>
    );
};

export default DealerFiles;
